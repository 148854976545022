import React from 'react'
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
} from 'mdbreact'

class CarouselQuote extends React.Component {
  render() {
    return (
      <MDBContainer>
        <MDBCarousel
          activeItem={1}
          length={1}
          showControls={false}
          showIndicators={false}
          interval={9000}
        >
          <MDBCarouselInner>
            <MDBCarouselItem itemId={this.props.quotenumber}>
              <div className="blockquote">
                <h2>{this.props.strapline}</h2>
                <h3 style={{ whiteSpace: 'pre-wrap' }}>{this.props.author}</h3>
              </div>
            </MDBCarouselItem>
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBContainer>
    )
  }
}

export default CarouselQuote
